@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@300;400;500;600;700&display=swap");
body {
  background: #f6f1f1;
  font-family: "Kumbh Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  --main-color: #3540b5;
}

.no-orphan {
  white-space: nowrap;
}

img {
  max-width: 100%;
}

.content {
  margin: 4rem auto;
}

.grid {
  width: 1100px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 18px;
  grid-row-gap: 18px;
  animation: fadeInAnimation ease 700ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.grid > * {
  position: relative;
  overflow: hidden;
  border-radius: 24px;
  padding: 1.5rem;
  display: flex;
  align-items: flex-start;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.div1 {
  padding: 0 0 1.5rem 0;
  grid-area: 1 / 1 / 3 / 2;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-self: center;
  text-align: center;
  background: white;
  padding-top: 0;
  overflow: visible;
}
.div2 {
  grid-area: 1 / 2 / 2 / 4;
  background: #541b86;
  color: white;
  align-items: flex-end;
}

.div3 {
  grid-area: 2 / 2 / 3 / 3;
  background: #1ed760;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
}

.div4 {
  grid-area: 2 / 3 / 3 / 4;
  background: #0e76a8;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.div5 {
  grid-area: 1 / 4 / 3 / 5;
  background: #035360;
  color: white;
  justify-content: flex-end;
}
.div6 {
  grid-area: 3 / 1 / 4 / 3;
  background: #31326f;
  color: white;
  align-items: flex-end;
}
.div7 {
  grid-area: 4 / 1 / 5 / 2;
  background: #222831;
  color: white;
  justify-content: flex-end;
}
.div8 {
  grid-area: 4 / 2 / 5 / 3;
  background: #1a7f77;
  color: white;
  align-items: flex-end;
}
.div9 {
  grid-area: 3 / 3 / 5 / 5;
  background: #ba2529;
  color: white;
}

.portrait-container {
  background-color: #282aa6;
  border-radius: 24px 24px 0px 0px;
  margin-bottom: 8px;
}

.portrait {
  margin-top: -40px;
  padding: 0;
  width: 100%;
}

.portrait img {
  margin: 0 auto;
  width: 90%;
  display: block;
  padding: 0;
}

.intro-container {
  padding: 1.5rem;
}

.introduction a {
  color: black;
  font-weight: 500;
  text-decoration-line: underline;
  text-decoration-style: "solid";
  text-decoration-color: #3540b5;
  text-underline-offset: 3px;
  text-decoration-thickness: 2px;
  transition: all 100ms linear;
}

.introduction a:hover {
  background: #3540b5;
  color: white;
}

p.introduction {
  line-height: 160%;
  font-size: 14px;
  padding: 0 6px;
  margin: 0;
  margin-bottom: 22px;
}

a.introduction-email {
  font-size: 13px;
  font-weight: 600;
  color: black;
  text-align: center;
  text-decoration: none;
  margin: 0 auto;
  margin-top: 22px;
  margin-bottom: 8px;
  border-radius: 100px;
  box-shadow: 0px 0px 0px 2px #eee;
  padding: 8px 12px 10px 12px;
  transition: box-shadow 100ms ease-in-out;
}

a.introduction-email:hover {
  box-shadow: 0px 0px 0px 4px #eee;
}

.recentlyplayingcard,
.nowplayingcard {
  width: 100%;
  font-weight: 500;
}

.spotify-logo {
  align-self: flex-end;
  cursor: pointer;
}

.song-title,
.song-artist {
  padding: 0;
  margin: 0;
}

.music-status {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.music-status-text {
  color: white;
  font-size: 13px;
  margin-left: 8px;
  font-weight: 600;
}

.music-gif {
  width: 18px;
  align-self: center;
}

.song-title {
  color: white;
  font-size: 16px;
  font-weight: 600;
  font-size: 16px;
}

.song-artist {
  color: #ddffe9;
  font-weight: 400;
  margin-top: 7px;
  font-size: 16px;
}

.loading-gif {
  position: absolute;
  top: 20%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 300px; /* Need a specific value to work */
  cursor: pointer;
  z-index: 99999;
}

.btn {
  font-family: "Kumbh Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background: white;
  -webkit-box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.7);
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.7);
  padding: 6px 14px;
  border-radius: 100px;
  text-decoration: none;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: box-shadow 100ms ease-out;
  z-index: 999;
  border: 0;
  cursor: pointer;
}

.btn:hover {
  -webkit-box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.7);
  box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.7);
}

.project-intro-btns a {
  text-decoration: none;
}

.btn-linkedin {
  margin-top: 44px;
  font-size: 13px;
  color: #0e76a8;
}

.btn-vipps {
  font-size: 14px;
  color: #ff5b24;
}

.btn-connect {
  font-size: 14px;
  color: #045461;
}

.btn-rentacomic {
  font-size: 14px;
  color: #b00c45;
}

.btn-norwegianrails {
  font-size: 14px;
  color: #342ead;
}

.btn-amigo {
  font-size: 14px;
  color: #1a7f77;
}

.btn-shifter {
  font-size: 14px;
  color: #ba2529;
}

.btn > span {
  font-size: 16px;
  padding-left: 5px;
}

.blob {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.blob-vipps {
  background: url("vectors/vipps-render.png") no-repeat,
    url("vectors/vipps.svg") no-repeat;
  background-size: 350px, 450px;
  background-position: 90% -180%, 120% 60%;
}

.blob-connect {
  background: url("vectors/connect-render.png") no-repeat,
    url("vectors/connect.svg") no-repeat;
  background-size: 75%, 125%;
  background-position: 50% 75%, 50% 30%;
}

.blob-rentacomic {
  background: url("vectors/rentacomic-render.png") no-repeat,
    url("vectors/rentacomic.svg") no-repeat;
  background-size: 60%, 80%;
  background-position: 90% 50%, 90% 10%;
}

.blob-norwegianrails {
  background: url("vectors/norwegianrails-render.png") no-repeat,
    url("vectors/norwegianrails.svg") no-repeat;
  background-size: 50%, 120%;
  background-position: 20% -850%, 160% -50%;
}

.blob-amigo {
  background: url("vectors/amigo-render.png") no-repeat,
    url("vectors/amigo.svg") no-repeat;
  background-size: 70%, 120%;
  background-position: 55% 168%, -90% 140%;
}

.blob-shifter {
  background: url("vectors/shifter-render.png") no-repeat,
    url("vectors/shifter.svg") no-repeat;
  background-size: 90%, 120%;
  background-position: 50% 70%, -50% -40%;
}

.popup-overlay .popup-content {
  position: relative;
  width: 100%;
  height: 100%;
  border: 0;
  padding: 0;
  overflow: scroll;
  animation: fadeIn ease-out 200ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.vipps-modal-content {
  background: #541b86;
  color: white;
}

.connect-modal-content {
  background: #035360;
  color: white;
}

.rentacomic-modal-content {
  background: #31326f;
  color: white;
}

.norwegianrails-modal-content {
  background: #222831;
  color: white;
}

.amigo-modal-content {
  background: #1a7f77;
  color: white;
}

.shifter-modal-content {
  background: #ba2529;
  color: white;
}

.project-modal {
  height: 100%;
  margin: 4rem;
}

.modal-closebtn {
  position: fixed;
  right: 38px;
  top: 28px;
  cursor: pointer;
  background: white;
  border-radius: 100px;
  height: 29px;
  width: 30px;
  padding-top: 6px;
  padding-left: 5px;
}

.modal-closebtn .material-ico {
  color: black;
}

.project-page {
  width: 1100px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.project-page .project-container {
  display: flex;
  flex-direction: row;
}

.scrollChevron {
  position: fixed;
  bottom: 1rem;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 80px; /* Need a specific value to work */
  cursor: pointer;
  z-index: 99999;
  transition: bottom 300ms ease-out;
}
.scrollChevron:hover {
  bottom: 0.7rem;
}

.hidden-chevron {
  display: none;
}

.scrollChevron .material-ico {
  font-size: 60px;
}

.project-intro {
  flex: 1;
  padding-right: 70px;
  padding-bottom: 20px;
}

.project-intro-main {
  font-size: 60px;
  font-weight: 600;
  margin: 0;
  padding: 0;
}

.project-intro-secondary {
  font-size: 20px;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 1.5rem;
}

.project-description {
  flex: 1;
  padding-left: 70px;
  line-height: 150%;
  font-size: 14px;
}

.project-grid {
  margin-top: 100px;
}

.project-grid > * {
  min-height: 100px;
  overflow: hidden;
}

.vipps-grid {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-template-rows: 200px 200px 200px 200px;
  grid-column-gap: 24px;
  grid-row-gap: 34px;
}

.vipps-grid .card-1 {
  grid-area: 1 / 1 / 3 / 6;
  background: url("project/vippskasse/vipps1.png") no-repeat;
  background-size: contain;
}
.vipps-grid .card-2 {
  grid-area: 1 / 6 / 3 / 8;
  background: url("project/vippskasse/vipps2.png") no-repeat;
  background-size: contain;
}
.vipps-grid .card-3 {
  grid-area: 1 / 8 / 3 / 10;
  background: url("project/vippskasse/vipps3.png") no-repeat;
  background-size: contain;
}
.vipps-grid .card-4 {
  grid-area: 3 / 1 / 4 / 5;
  background: url("project/vippskasse/vipps4.png") no-repeat;
  background-size: cover;
  border-radius: 24px;
  box-shadow: 0px 0px 0px 13px #fafcff inset;
}
.vipps-grid .card-5 {
  grid-area: 4 / 1 / 5 / 3;
  background: url("project/vippskasse/vipps5.png") no-repeat;
  background-size: cover;
  border-radius: 24px;
  box-shadow: 0px 0px 0px 13px #fafcff inset;
}
.vipps-grid .card-6 {
  grid-area: 4 / 3 / 5 / 5;
  background: url("project/vippskasse/vipps6.png") no-repeat;
  background-size: cover;
  border-radius: 24px;
  box-shadow: 0px 0px 0px 13px #fafcff inset;
}
.vipps-grid .card-7 {
  grid-area: 3 / 5 / 5 / 10;
  background: url("project/vippskasse/vipps7.png") no-repeat;
  background-size: contain;
}

.connect-grid {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-template-rows: 200px 200px 200px 200px;
  grid-column-gap: 24px;
  grid-row-gap: 34px;
}

.connect-grid .card-1 {
  grid-area: 1 / 1 / 3 / 3;
  background: url("project/connect/connect1.png") no-repeat;
  background-size: contain;
}
.connect-grid .card-2 {
  grid-area: 1 / 3 / 3 / 5;
  background: url("project/connect/connect2.png") no-repeat;
  background-size: contain;
}
.connect-grid .card-3 {
  grid-area: 1 / 5 / 3 / 10;
  background: url("project/connect/connect3.png") no-repeat;
  background-size: contain;
}
.connect-grid .card-4 {
  grid-area: 3 / 1 / 5 / 6;
  background: url("project/connect/connect4.png") no-repeat;
  background-size: contain;
}
.connect-grid .card-5 {
  grid-area: 3 / 6 / 5 / 8;
  background: url("project/connect/connect5.png") no-repeat;
  background-size: contain;
}
.connect-grid .card-6 {
  grid-area: 3 / 8 / 5 / 10;
  background: url("project/connect/connect6.png") no-repeat;
  background-size: contain;
}

.rentacomic-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 500px;
  grid-column-gap: 24px;
  grid-row-gap: 0px;
}

.rentacomic-grid .card-1 {
  grid-area: 1 / 1 / 2 / 2;
  background: url("project/rentacomic/comic1.png") no-repeat;
  background-size: contain;
}
.rentacomic-grid .card-2 {
  grid-area: 1 / 2 / 2 / 3;
  background: url("project/rentacomic/comic2.png") no-repeat;
  background-size: contain;
}
.rentacomic-grid .card-3 {
  grid-area: 1 / 3 / 2 / 4;
  background: url("project/rentacomic/comic3.png") no-repeat;
  background-size: contain;
}
.rentacomic-grid .card-4 {
  grid-area: 1 / 4 / 2 / 5;
  background: url("project/rentacomic/comic4.png") no-repeat;
  background-size: contain;
}

.norwegianrails-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 450px;
  grid-column-gap: 24px;
  grid-row-gap: 0px;
}

.norwegianrails-grid .card-1 {
  grid-area: 1 / 1 / 2 / 2;
  background: url("project/norwegianrails/rails1.png") no-repeat;
  background-size: contain;
}
.norwegianrails-grid .card-2 {
  grid-area: 1 / 2 / 2 / 3;
  background: url("project/norwegianrails/rails2.png") no-repeat;
  background-size: contain;
}
.norwegianrails-grid .card-3 {
  grid-area: 1 / 3 / 2 / 4;
  background: url("project/norwegianrails/rails3.png") no-repeat;
  background-size: contain;
}
.norwegianrails-grid .card-4 {
  grid-area: 1 / 4 / 2 / 5;
  background: url("project/norwegianrails/rails4.png") no-repeat;
  background-size: contain;
}
.norwegianrails-grid .card-5 {
  grid-area: 1 / 5 / 2 / 6;
  background: url("project/norwegianrails/rails5.png") no-repeat;
  background-size: contain;
}

.amigo-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 450px;
  grid-column-gap: 24px;
  grid-row-gap: 0px;
}

.amigo-grid .card-1 {
  grid-area: 1 / 1 / 2 / 2;
  background: url("project/amigo/amigo1.png") no-repeat;
  background-size: contain;
}
.amigo-grid .card-2 {
  grid-area: 1 / 2 / 2 / 3;
  background: url("project/amigo/amigo2.png") no-repeat;
  background-size: contain;
}
.amigo-grid .card-3 {
  grid-area: 1 / 3 / 2 / 4;
  background: url("project/amigo/amigo3.png") no-repeat;
  background-size: contain;
}
.amigo-grid .card-4 {
  grid-area: 1 / 4 / 2 / 5;
  background: url("project/amigo/amigo4.png") no-repeat;
  background-size: contain;
}
.amigo-grid .card-5 {
  grid-area: 1 / 5 / 2 / 6;
  background: url("project/amigo/amigo5.png") no-repeat;
  background-size: contain;
}

.shifter-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 400px;
  grid-column-gap: 24px;
  grid-row-gap: 0px;
}

.shifter-grid .card-1 {
  background: url("project/shifter/shifter1.png") no-repeat;
  background-size: contain;
}
.shifter-grid .card-2 {
  background: url("project/shifter/shifter2.png") no-repeat;
  background-size: contain;
}

@media only screen and (max-width: 1150px) {
  .content {
    margin: 0.8rem;
    padding: 0;
  }
  .grid {
    width: 350px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr 220px 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .hide-on-mobile {
    display: none;
  }

  .div1 {
    grid-area: 1 / 1 / 2 / 3;
    margin-top: 50px;
  }
  .div2 {
    grid-area: 3 / 1 / 4 / 3;
  }
  .div3 {
    grid-area: 2 / 1 / 3 / 2;
  }
  .div4 {
    grid-area: 2 / 2 / 3 / 3;
  }
  .div5 {
    grid-area: 4 / 1 / 5 / 3;
  }
  .div6 {
    grid-area: 5 / 1 / 6 / 3;
  }
  .div7 {
    grid-area: 6 / 1 / 7 / 3;
  }
  .div8 {
    grid-area: 7 / 1 / 8 / 3;
  }
  .div9 {
    grid-area: 8 / 1 / 9 / 3;
  }

  .blob-vipps {
    background: url("vectors/vipps-render.png") no-repeat,
      url("vectors/vipps.svg") no-repeat;
    background-size: 300px, 400px;
    background-position: 50% 50%, 50% 50%;
  }

  .blob-connect {
    background: url("vectors/connect-render.png") no-repeat,
      url("vectors/connect.svg") no-repeat;
    background-size: 65%, 125%;
    background-position: 50% 75%, 50% 40%;
  }

  .blob-rentacomic {
    background: url("vectors/rentacomic-render.png") no-repeat,
      url("vectors/rentacomic.svg") no-repeat;
    background-size: 90%, 100%;
    background-position: 50% 30%, 50% 50%;
  }

  .blob-norwegianrails {
    background: url("vectors/norwegianrails-render.png") no-repeat,
      url("vectors/norwegianrails.svg") no-repeat;
    background-size: 65%, 110%;
    background-position: 50% 70%, 50% 50%;
  }

  .blob-amigo {
    background: url("vectors/amigo-render.png") no-repeat,
      url("vectors/amigo.svg") no-repeat;
    background-size: 65%, 110%;
    background-position: 50% 30%, 50% 20%;
  }

  .blob-shifter {
    background: url("vectors/shifter-render.png") no-repeat,
      url("vectors/shifter.svg") no-repeat;
    background-size: 90%, 120%;
    background-position: 50% 50%, 50% 50%;
  }

  .project-page {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }

  .project-page .project-container {
    flex-direction: column;
  }

  .project-description {
    padding-left: 0;
  }

  .project-modal {
    margin: 1rem;
  }

  .project-intro {
    padding-right: 0;
  }

  .project-intro-main {
    margin-top: 12px;
    font-size: 50px;
    max-width: 280px;
  }

  .modal-closebtn {
    right: 20px;
  }

  .project-grid {
    margin-top: 40px;
  }
  .vipps-grid {
    width: 350px;
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 260px 330px 260px;
  }

  .vipps-grid .card-1 {
    grid-area: 1 / 1 / 2 / 3;
  }
  .vipps-grid .card-2 {
    grid-area: 2 / 1 / 3 / 2;
  }
  .vipps-grid .card-3 {
    grid-area: 2 / 2 / 3 / 3;
  }
  .vipps-grid .card-4 {
    grid-area: 4 / 1 / 5 / 2;
    display: none;
  }
  .vipps-grid .card-5 {
    grid-area: 5 / 1 / 6 / 2;
    display: none;
  }
  .vipps-grid .card-6 {
    grid-area: 6 / 1 / 7 / 2;
    display: none;
  }
  .vipps-grid .card-7 {
    grid-area: 3 / 1 / 4 / 3;
  }

  .connect-grid {
    width: 350px;
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 330px 260px 260px 330px;
  }

  .connect-grid .card-1 {
    grid-area: 1 / 1 / 2 / 2;
  }
  .connect-grid .card-2 {
    grid-area: 1 / 2 / 2 / 3;
  }
  .connect-grid .card-3 {
    grid-area: 2 / 1 / 3 / 3;
  }
  .connect-grid .card-4 {
    grid-area: 3 / 1 / 4 / 3;
  }
  .connect-grid .card-5 {
    grid-area: 4 / 1 / 5 / 2;
  }
  .connect-grid .card-6 {
    grid-area: 4 / 2 / 5 / 3;
  }

  .rentacomic-grid {
    width: 350px;
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 360px 360px;
  }

  .rentacomic-grid .card-1 {
    grid-area: 1 / 1 / 2 / 2;
  }
  .rentacomic-grid .card-2 {
    grid-area: 1 / 2 / 2 / 3;
  }
  .rentacomic-grid .card-3 {
    grid-area: 2 / 1 / 3 / 2;
  }
  .rentacomic-grid .card-4 {
    grid-area: 2 / 2 / 3 / 3;
  }

  .norwegianrails-grid {
    width: 350px;
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 740px 360px 360px;
  }

  .norwegianrails-grid .card-1 {
    grid-area: 1 / 1 / 2 / 3;
  }
  .norwegianrails-grid .card-2 {
    grid-area: 2 / 1 / 3 / 2;
  }
  .norwegianrails-grid .card-3 {
    grid-area: 2 / 2 / 3 / 3;
  }
  .norwegianrails-grid .card-4 {
    grid-area: 3 / 1 / 4 / 2;
  }
  .norwegianrails-grid .card-5 {
    grid-area: 3 / 2 / 4 / 3;
  }

  .amigo-grid {
    width: 350px;
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 740px 360px 360px;
  }

  .amigo-grid .card-1 {
    grid-area: 1 / 1 / 2 / 3;
  }
  .amigo-grid .card-2 {
    grid-area: 2 / 1 / 3 / 2;
  }
  .amigo-grid .card-3 {
    grid-area: 2 / 2 / 3 / 3;
  }
  .amigo-grid .card-4 {
    grid-area: 3 / 1 / 4 / 2;
  }
  .amigo-grid .card-5 {
    grid-area: 3 / 2 / 4 / 3;
  }

  .shifter-grid {
    width: 350px;
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: 1fr;
    grid-template-rows: 290px 290px;
  }

  .shifter-grid .card-1 {
    grid-area: 1 / 1 / 2 / 2;
  }
  .shifter-grid .card-2 {
    grid-area: 2 / 1 / 3 / 2;
  }
}

@media only screen and (min-width: 1150px) {
  .hide-on-desktop {
    display: none;
  }
}
